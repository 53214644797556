.hero-wrapper {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0 0 24px 24px;
    overflow: hidden;
    .hero-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        padding: 6.2rem 0 3.4rem 0;

        // ======HERO======
        .hero-top {
            display: flex;
            flex-direction: column;
            align-items: center;
            .heading {
                color: #ffffff;
                display: flex;
                flex-direction: column;
                align-items: center;
                .hero-heading {
                    text-align: center;
                    font-size: 4rem;
                    line-height: 120%;
                    letter-spacing: -3px;
                    margin-bottom: 0.8rem;
                }
                .sub-heading {
                    letter-spacing: -0.4px;
                    font-size: 1.24rem;
                    text-align: center;
                }
            }
        }

        .hero-bottom {
            margin-top: 2.4rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 930px) {
    .hero-wrapper {
        .hero-container {
            padding: 5.4rem 0 3.4rem 0;
            .hero-top {
                .heading {
                    .hero-heading {
                        font-size: 3.2rem;
                    }
                    .sub-heading {
                        font-size: 1rem;
                    }
                }
            }

            .hero-bottom {
                margin-top: 2rem;
                img {
                    width: 80%;
                }
            }
        }
    }
}

@media screen and (max-width: 450px) {
    .hero-wrapper {
        .hero-container {
            padding: 3.6rem 0 2rem 0;
            width: 90%;
            .hero-top {
                .heading {
                    .hero-heading {
                        font-size: 2rem;
                        letter-spacing: -2px;
                    }
                    .sub-heading {
                        font-size: 0.8rem;
                    }
                }
            }
            .hero-bottom {
                margin-bottom: 3rem;
                img {
                    width: 100%;
                }
            }
        }
    }
}