@tailwind base;
@tailwind components;
@tailwind utilities;

/* =================TYPOGRAPHY================= */

/* -----SF Pro----- */
@font-face {
  font-family: "Inter";
  src: url("../src/assets/fonts/Inter-VariableFont_opsz\,wght.ttf");
}

.light {
  font-family: "Inter";
  font-weight: 300;
}
.regular {
  font-family: "Inter";
  font-weight: 400;
}
.medium {
  font-family: "Inter";
  font-weight: 500;
}
.semibold {
  font-family: "Inter";
  font-weight: 600;
}
.bold {
  font-family: "Inter";
  font-weight: 700;
}
.heavy {
  font-family: "Inter";
  font-weight: 800;
}

/* =================*================= */
*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* =================Section================= */
section {
  height: 100dvh;
}

.herocta-button {
  margin-top: 2.1rem;
  a {
      text-decoration: none;
      background: #ffffff;
      color: #000000;
      letter-spacing: -0.3px;
      font-size: 0.96rem;
      line-height: 100%;
      padding: 8px 16px;
      border-radius: 6px;
  }
}

@media screen and (max-width: 930px) {
  .herocta-button {
    margin-top: 2rem;
    a {
      font-size: 0.9rem;
    }
  }
}

@media screen and (max-width: 450px) {
  .herocta-button {
    margin-top: 1.4rem;
    a {
      font-size: 0.8rem;
    }
  }
}

html {
  scroll-behavior: smooth;
}