.testimonial-wrapper {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    .testimonial-container {
        width: 70%;
        padding: 5rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .testimonial-heading {
            width: 100%;
            font-size: 2.2rem;
            letter-spacing: -2px;
            text-align: center;
            margin-bottom: 2rem;
        }
        .logos {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
        }
    }
}

@media screen and (max-width: 930px) {
    .testimonial-wrapper {
        .testimonial-container {
            width: 85%;
            padding: 3.6rem 0;
            .testimonial-heading {
                font-size: 2rem;
                margin-bottom: 1.6rem;
            }
        }
    }
}

@media screen and (max-width: 450px) {
    .testimonial-wrapper {
        .testimonial-container {
            width: 90%;
            padding: 2rem 0;
            .testimonial-heading {
                font-size: 1.6rem;
                letter-spacing: -1.2px;
                margin-bottom: 1rem;
            }
            .logos {
                .logo {
                    width: 90px;
                }
            }
        }
    }
}