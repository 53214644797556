.about-wrapper {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    .about-container {
        display: flex;
        flex-direction: row;
        align-items: start;
        justify-content: center;
        padding: 5rem 0;
        width: 70%;
        gap: 4rem;
        .about-content {
            display: flex;
            flex-direction: column;
            align-items: start;
            gap: 0.8rem;
            .about-heading {
                font-size: 2.2rem;
                letter-spacing: -0.6px;
            }
            .about-description {
                font-size: 1.06rem;
                color: #515151;
                span {
                    letter-spacing: -0.2px;
                    color: #000000;
                }
            }
            .cta-button {
                margin-top: 0.6rem;
                a {
                    text-decoration: none;
                    background: #000;
                    color: #fff;
                    letter-spacing: -0.3px;
                    font-size: 0.9rem;
                    padding: 8px 14px;
                    border-radius: 6px;
                }
            }
        }
    }
}

@media screen and (max-width: 930px) {
    .about-wrapper {
        .about-container {
            width: 85%;
            gap: 3.2rem;
            .about-content {
                gap: 0.8rem;
                .about-heading {
                    font-size: 1.8rem;
                }
                .about-description {
                    font-size: 0.9rem;
                }
                .cta-button {
                    margin-top: 0.2rem;
                    a {
                        font-size: 0.8rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 930px) {
    .about-wrapper {
        .about-container {
            width: 90%;
            gap: 2rem;
            flex-direction: column-reverse;
            padding: 2rem 0;
            .about-content {
                .about-heading {
                    font-size: 2rem;
                }
            }
        }
    }
}