.contact-wrapper {
    color: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .contact-container {
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        padding: 5rem 0 3rem 0;
        .contact-heading {
            display: flex;
            flex-direction: column;
            align-items: center;
            p {
                font-size: 3.2rem;
                text-align: center;
                line-height: 120%;
                letter-spacing: -3px;
                margin-bottom: 0.8rem;
            }
            span {
                letter-spacing: -0.4px;
                font-size: 1.26rem;
                text-align: center;
                opacity: 0.7;
            }
        }
        .contact-meeting {
            width: 100%;
            height: max-content;
            margin-top: 2rem;
        }
    }
}

@media screen and (max-width: 930px) {
    .contact-wrapper {
        .contact-container {
            width: 85%;
            padding: 4rem 0 3rem 0;
            .contact-heading {
                p {
                    font-size: 2.4rem;
                    letter-spacing: -2px;
                    margin: 0.7rem;
                }
                span {
                    font-size: 1rem;
                }
            }
        }
    }
}

@media screen and (max-width: 450px) {
    .contact-wrapper {
        .contact-container {
            width: 90%;
            padding: 3rem 0 1rem 0;
            .contact-heading {
                p {
                    font-size: 2rem;
                    letter-spacing: -1.4px;
                    margin-bottom: 0.6rem;
                }
                span {
                    letter-spacing: -0.2px;
                    font-size: 0.8rem;
                    margin-bottom: 2rem;
                }
            }
        }
    }
}