.whatsincluded-wrapper {
    color: #fff;
    background: #000;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    .whatsincluded-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 4rem 0 6rem 0;
        width: 70%;
        .whatsincluded-heading {
            width: 100%;
            font-size: 2.6rem;
            letter-spacing: -2px;
            text-align: center;
            margin-bottom: 3rem;
        }
        .card-deck {
            width: auto;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            align-items: start;
            gap: 2.6rem;
            margin-bottom: 2rem;
            .main-card {
                display: flex;
                flex-direction: column;
                align-items: center;
                .card {
                    width: 280px;
                    .image-box {
                        border-radius: 12px;
                        overflow: hidden;
                    }
                    .card-description {
                        margin-top: 0.8rem;
                        p {
                            font-size: 1.1rem;
                            letter-spacing: -0.4px;
                        }
                        span {
                            display: block;
                            letter-spacing: -0.2px;
                            font-size: 0.9rem;
                            opacity: 0.8;
                        }
                    }
                }
            }
        }

        .includedcta-button {
            a {
                font-size: 1.4rem;
            }
        }
    }
}


@media screen and (max-width: 930px) {
    .whatsincluded-wrapper {
        .whatsincluded-container {
            width: 85%;
            padding: 3.6rem 0 5rem 0;
            .whatsincluded-heading {
                font-size: 2rem;
                margin-bottom: 2.4rem;
            }
            .card-deck {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 2rem;
                margin-bottom: 1.6rem;
                .main-card {
                    .card {
                        width: 240px;
                    }
                }
            }
        }
        .includedcta-button {
            a {
                font-size: 1.2rem;
            }
        }
    }
}

@media screen and (max-width: 450px) {
    .whatsincluded-wrapper {
        .whatsincluded-container {
            width: 90%;
            padding: 2rem 0 3rem 0;
            .whatsincluded-heading {
                font-size: 1.6rem;
                letter-spacing: -1.2px;
                margin-bottom: 1.8rem;
            }
            .card-deck {
                gap: 1rem;
                grid-template-columns: repeat(2, 1fr);
                align-items: start;
                .main-card {
                    .card {
                        width: 172px;
                        .card-description {
                            p {
                                font-size: 1rem;
                                letter-spacing: -0.2px;
                            }
                            span {
                                font-size: 0.8rem;
                                line-height: 20px;
                            }
                        }
                    }
                }
            }
        }
        .includedcta-button {
            a {
                font-size: 0.9rem !important;
            }
        }
    }
}